import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e3d24a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "review" }
const _hoisted_3 = { class: "reviewer" }
const _hoisted_4 = { class: "reviewer-info" }
const _hoisted_5 = { class: "reviewer-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "review-card",
    id: _ctx.dark_mode ? 'dark-mode' : 'default-mode'
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.review), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, " • " + _toDisplayString(_ctx.name), 1)
      ])
    ])
  ], 8, _hoisted_1))
}