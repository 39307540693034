import { createStore } from "vuex";
export type State = {
  estimate_popup_open: boolean;
};


export const store = createStore({
  state: {
    estimate_popup_open: false,
  },
  getters: {},
  mutations:{},

  actions: {},
  modules: {},
});
