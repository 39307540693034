
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    image_path: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },

  data() {
    return {
      isFlipped: false,
    };
  },

  methods: {
    flipCard() {
      this.isFlipped = !this.isFlipped;
    },
  },
});
