
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    review: {
      type: String,
      required: true,
    },
    dark_mode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
