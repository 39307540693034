
import { defineComponent } from "vue";
import ServiceCard from "@/components/service-card.vue";
/* import Header from "@/components/header.vue"; */

import ReviewCard from "@/components/review-card.vue";
export default defineComponent({
  name: "HomeView",
  components: { ServiceCard, ReviewCard },
});
