import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { MotionPlugin } from '@vueuse/motion'
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZkuYPdPA7xSQ6MVFJu4V3RVnB4ATRvIM",
  authDomain: "covenant-painters.firebaseapp.com",
  projectId: "covenant-painters",
  storageBucket: "covenant-painters.appspot.com",
  messagingSenderId: "761016045623",
  appId: "1:761016045623:web:f5d1a5e3f921ee9030afb2",
  measurementId: "G-J7S2VGK7LC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);

let vue_app: any;

onAuthStateChanged(auth, () => {
  if (!vue_app) {
    vue_app = createApp(App).use(store).use(router).use(MotionPlugin).mount("#app");
  }
});

export { app, db, analytics, functions, auth };
