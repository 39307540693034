<template>
  <div class="popup-wrap" v-if="$store.state.estimate_popup_open" v-motion-fade>
    <!-- Step #1 -->
    <div v-if="step == 1" class="popup" v-motion-pop>
      <div class="title">please request your quote below</div>
      <div class="subtitle">a team member will contact you shortly.</div>

      <form class="form" action="https://formspree.io/f/xnqrozbn" method="POST">
        <input type="text" placeholder="Name" name="name" v-model="name" required />

        <input
          type="tel"
          placeholder="Phone"
          v-model="phone_number"
          @input="validatePhone"
          required
          :class="invalid_phone ? 'error' : ''"
        />
        <input type="email" name="email" placeholder="Email*" v-model="email" required />

        <input type="text" name="address" placeholder="(optional) Address" v-model="address" />

        <textarea
          cols="30"
          rows="10"
          name="About the project"
          placeholder="(optional) How can we help you with your project?"
          v-model="notes"
        ></textarea>
      </form>
      <div class="error-box">
        {{ error_msg }}
      </div>
      <div class="p-controls">
        <button class="back" @click="$store.state.estimate_popup_open = false">
          Back
        </button>

        <button class="action"  @click="submitForm()">Next</button>
      </div>
    </div>

    <!-- Step #2 -->
    <div v-if="step == 2" class="popup" v-motion-pop>
      <div class="stage">
        <svg
          class="box bounce-7"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 50 50"
          style="enable-background: new 0 0 50 50"
          xml:space="preserve"
        >
          <circle style="fill: #a2c3e2" cx="25" cy="25" r="25" />
          <polyline
            style="
              fill: none;
              stroke: #ffffff;
              stroke-width: 2;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-miterlimit: 10;
            "
            points="  38,15 22,33 12,25 "
          />
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      </div>
      <div class="title">Your request has been sent.</div>
      <div class="subtitle">
        Thank you <span class="blue">{{ name }}</span> for trusting us with your
        project. <br />
        We will get back to you as soon as possible.
      </div>

      <div class="p-controls">
        <button
          class="back"
          id="wide"
          @click="$store.state.estimate_popup_open = false"
        >
          Back Home
        </button>
      </div>
    </div>
  </div>
  <div class="header-wrap">
    <div class="header" v-motion-slide-top :delay="100">
      <div class="logo">
        <img src="@/assets/logo.svg" alt="logo" />
      </div>
      <!-- <h2 class="secondary">Covenant Painters</h2> -->

      <!-- <div class="navbar" role="navigation">
        <div class="nav-item"><router-link to="/">Home</router-link></div>
        <div class="nav-item">
          <router-link to="/FAQ">FAQ</router-link>
        </div>
      </div> -->
    </div>
  </div>
  <router-view />
</template>

<script>
export default {
  data() {
    return {
      phone_number: "",
      name: "",
      email: "",
      notes: "",
      address: "",

      invalid_phone: false,
      error_msg: "",
      step: 1,
    };
  },

  methods: {
    validatePhone() {
      let x = this.phone_number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone_number = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

      this.phone_number.length > 13
        ? (this.invalid_phone = false)
        : (this.invalid_phone = true);
    },

    displayError(msg) {
      this.error_msg = msg;

      setTimeout(() => {
        this.error_msg = "";
      }, 4000);
    },

    async submitForm() {
      if (!this.invalid_phone && this.name && this.email) {
        //send form data to Formspree

         let response = await fetch("https://formspree.io/f/xnqrozbn", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.name,
            phone: this.phone_number,
            email: this.email,
            address: this.address,
            message: this.notes,
          }),
        });

        if (response.status == 200) {
          this.step = 2;
        } else {
          this.displayError("Something went wrong. Please try again.");
        }

      } else {
        this.displayError("Please fill out all required fields.");
      }
    },
  },

  computed: {},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lora", serif;
}

#app {
  text-align: center;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.error-box {
  color: red;
  font-size: 12px;
  margin-top: 10px;
}

#wide {
  width: 100% !important;
}

.blue {
  color: #5c7fa3;
  text-transform: capitalize;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(209, 209, 209);
  border-radius: 8px;
}

.header-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1800px;
  position: relative;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #fff;

  h2 {
    font-size: 35px;
    font-weight: 500;
  }
}
.logo {
  padding: 1rem 0;

  img {
    width: 300px;
  }
}

.popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  z-index: 1002;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup {
  background: #ffffff;
  box-shadow: 0px 3.34648px 26.7718px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding: 2rem 3rem;

  svg {
    width: 50px;
    height: 50px;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;

    color: #000000;
    margin: 1rem 0;
  }

  .subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;

    color: #424242;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;

  select,
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 60%;
    height: 50px;
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0 10px;
    padding-right: 20px;
    background: #f0f0f0;

    &:focus {
      outline: none;
    }
  }

  textarea {
    max-width: 435px;
    padding: 10px;
  }
}
.p-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 3rem;
  .action {
    width: 20%;
    height: 50px;
    border: none;
    border-radius: 10px;
    background: #68a5de;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    cursor: pointer;

    &:hover {
      background: #609fdb;
    }
  }
  .back {
    width: 20%;
    height: 50px;
    border: none;
    border-radius: 10px;
    background: #ffffff;
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    cursor: pointer;
    &:hover {
      background: #e7e7e7;
    }
  }
}
.error {
  border: 1px solid red !important;
}

.stage {
  display: flex;
  width: 100%;
}
.box {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  height: 100px;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  width: 200px;
}
.bounce-7 {
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-30px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1360px) {
  .form {
    select,
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
      width: 100%;
    }
  }
}
/* Small screens */
@media only screen and (max-width: 1000px) {
  .popup-wrap {
    padding: 0 1rem;
    background: #fff;
  }
  .popup {
    padding: 2rem;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    overflow-y: scroll;
    .title {
      font-size: 30px;
    }
    .subtitle {
      font-size: 16px;
    }
  }

  .form {
    select,
    input[type="text"],
    input[type="tel"],
    textarea {
      width: 100%;
    }
  }
}
</style>
