<template>
  <div class="about">
    <h1>FAQ is coming up. 😊</h1>
  </div>
</template>

<style lang="scss" scoped>

  .about {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
</style>
